import { navigate, graphql } from 'gatsby'
import React, { useEffect } from 'react'
import { useShoppingCart, useAuth } from '@ecommerce/shared'
import ConfirmationPage from '../../components/SignUp/ConfirmationPage'
import { extractPageData } from '../../components/SignUp/utils'
import { SignupPage } from '../../types/PgPages'
import { sendRegisterEvent } from '../../utils/events'

const SignupSuccess = ({ location, data }: SignupPage) => {
  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendRegisterEvent(cart, entityInfo)
  }, [])

  const { pageData, template } = extractPageData(data)

  return (
    <ConfirmationPage
      pageTitle={pageData?.title ?? ''}
      bgImage={template?.backgroundImage?.file?.url ?? ''}
      icon={template?.icon?.file?.url ?? ''}
      title={template?.pageHeading ?? ''}
      text={template?.subtitle ?? ''}
      topButtonText="Inicia sesión"
      topButtonAction={() => navigate('/login')}
      bottomButtonText="Ir al Inicio"
      bottomButtonAction={() => navigate('/')}
    />
  )
}

export default SignupSuccess

export const query = graphql`
  query SignupSuccessPage($signupSuccessId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $signupSuccessId } }) {
      edges {
        node {
          title
          description
          keywords
          template {
            ... on ContentfulTmSignup {
              contentful_id
              pageHeading
              subtitle
              infoText
              icon {
                file {
                  url
                }
              }
              backgroundImage {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
